import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import i18n from './i18n/i18n'

import Element from "element-ui"
import Cookies from "js-cookie"
import "./styles/element-variables.scss"

Vue.use(Element, {
  size: Cookies.get("size") || "medium", // set element-ui default size
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
