<template>
  <div id="app">
    <router-view />
    <div class="madk" v-if="ServerStatus != 1">
      <div class="msg" v-if="ServerStatus == 0" :style="{ color: themeColor }">
        {{ $t("_server_status_0") }}
      </div>
      <div class="msg" v-if="ServerStatus == 2" :style="{ color: themeColor }">
        {{ $t("_server_status_2") }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      ServerStatus: 0, // 載入中
      themeColor: glob.themeColor,
    };
  },
  mounted() {
    this.getServerStatus();
  },
  methods: {
    getServerStatus() {
      axios
        .get("/api/server/status")
        .then((res) => {
          console.log(res.data);
          if (res.data.result == "success") {
            this.ServerStatus = 1; // 正常
          } else {
            this.ServerStatus = 2; // 維護中
          }
        })
        .catch((err) => {
          console.log("取得失敗", err.response);
          if (
            err.response &&
            err.response.data.msg === "Server is in maintainence now"
          ) {
            this.ServerStatus = 2; // 維護中
          } else {
            this.ServerStatus = 1; // 正常
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/globalstyle.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100vh;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.madk {
  background: #000c;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  // backdrop-filter: blur(5px);
  .msg {
    color: #f9c901;
    font-size: 26px;
  }
}
</style>
