import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/LoginView.vue";
import LellyLoginView from "../views/LellyLoginView.vue";
import loginView3 from "../views/loginView3.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: glob.designVer == "1" ? LoginView
      : glob.designVer == "2" ? LellyLoginView
        : loginView3,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
