<template>
  <div class="LeliLogo">
    <img :src="crown" alt="crown" class="crown" />
    <div class="words_box">
      <img
        :src="item"
        alt="words"
        :class="`words words_${index + 1}`"
        v-for="(item, index) in words"
        :key="index"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      crown: require("@/assets/images/Lelly/logo/Crown.svg"),
      words: [
        require("@/assets/images/Lelly/logo/1.svg"),
        require("@/assets/images/Lelly/logo/2.svg"),
        require("@/assets/images/Lelly/logo/3.svg"),
        require("@/assets/images/Lelly/logo/4.svg"),
        require("@/assets/images/Lelly/logo/5.svg"),
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.LeliLogo {
  margin: 20px 0;
  .crown {
    height: 100px;
    max-width: 100%;
    padding: 0 10px;
    // animation: brightness 3s forwards ease-in-out;
  }
  .words {
    margin: 0 8px;
    height: 25px;
    max-width: calc(20% - 20px);
    transform: rotateY(90deg);
    filter: brightness(0%);
    /* animation 參數設定 */
    // animation-name: MoveRotateY; /*動畫名稱，需與 keyframe 名稱對應*/
    // animation-duration: 1s; /*動畫持續時間，單位為秒*/
    // // animation-iteration-count: forwards; /*動畫次數，infinite 為無限次*/
    // animation-fill-mode: forwards;
    // animation-timing-function: ease-in-out;
    animation: MoveRotateY 1s forwards ease-in-out;
  }

  @for $i from 1 through 5 {
    .words_#{$i} {
      animation-delay: ($i * 0.25s);
    }
  }
}

@keyframes MoveRotateY {
  0% {
    transform: rotateY(90deg);
    // filter: brightness(0%);
  }

  50% {
    // filter: brightness(300%);
  }

  100% {
    transform: rotateY(0deg);
    filter: brightness(100%);
  }
}

@keyframes brightness {
  0% {
    filter: brightness(100%);
  }

  50% {
    filter: brightness(100%);
  }

  75% {
    filter: brightness(200%);
  }

  100% {
    filter: brightness(100%);
  }
}
</style>
