var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"login_page",attrs:{"id":"login-page"}},[_c('div',{attrs:{"id":"login-box"}},[_c('div',{staticClass:"lang",class:{ noBg: !_vm.showLangs }},_vm._l((_vm.languages),function(language,index){return _c('div',{key:index,on:{"click":function($event){return _vm.selectLang(language, language.name, index)}}},[(_vm.showLangs || index == 0)?_c('img',{attrs:{"src":language.img,"alt":""}}):_vm._e(),(_vm.showLangs)?_c('hr',{ref:"hr",refInFor:true}):_vm._e()])}),0),_c('div',{attrs:{"id":"logo-img"}},[_c('img',{attrs:{"src":_vm.logo,"alt":""}})]),_c('form',{ref:"form"},[_c('div',{staticClass:"input_area"},[_c('div',{staticClass:"input_box input_margin",class:[
            {
              redUnderline:
                (!_vm.account.length && _vm.accountClicked == true) || _vm.incorrect,
            },
            {
              greenUnderline: _vm.account.length,
            },
          ]},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.account),expression:"account"}],attrs:{"type":"text"},domProps:{"value":(_vm.account)},on:{"click":function($event){_vm.accountClicked = true},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login()},"input":function($event){if($event.target.composing)return;_vm.account=$event.target.value}}})]),_c('div',{staticClass:"input_box",class:[
            { redUnderline: !_vm.password.length && _vm.passwordClicked == true },
            { greenUnderline: _vm.password.length },
          ]},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"type":"password"},domProps:{"value":(_vm.password)},on:{"click":function($event){_vm.passwordClicked = true},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login()},"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})])]),_c('div',{staticClass:"button_group"},[_c('div',{staticClass:"button",on:{"click":function($event){$event.preventDefault();return _vm.login()}}},[_c('span',{staticClass:"button_text"},[_vm._v(" "+_vm._s(_vm.$t("_login"))+" ")])]),_c('div',{staticClass:"button",on:{"click":function($event){$event.preventDefault();return _vm.demoFun.apply(null, arguments)}}},[_c('span',{staticClass:"button_text"},[_vm._v(" "+_vm._s(_vm.$t("_testplay"))+" ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.incorrect),expression:"incorrect"}],ref:"incorrect"},[_c('div',{staticClass:"error_text"},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("_empty"))+" ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('i',{staticClass:"far fa-user"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('i',{staticClass:"fas fa-lock"})])
}]

export { render, staticRenderFns }