<template>
  <div id="login-page" ref="login_page">
    <div id="login-box">
      <div class="lang" :class="{ noBg: !showLangs }">
        <!-- <div class="language">{{ $t("_language") }}</div> -->
        <div
          v-for="(language, index) in languages"
          :key="index"
          @click="selectLang(language, language.name, index)"
        >
          <img v-if="showLangs || index == 0" :src="language.img" alt />
          <hr v-if="showLangs" ref="hr" />
        </div>
      </div>
      <div id="logo-img">
        <img :src="logo" alt />
      </div>
      <!-- <h1>代理商管理系統 2.0</h1> -->
      <!-- <h1>{{ $t(`${title}`) }}</h1> -->
      <form ref="form">
        <div class="input_area">
          <div
            class="input_box input_margin"
            :class="[
              {
                redUnderline:
                  (!account.length && accountClicked == true) || incorrect,
              },
              {
                greenUnderline: account.length,
              },
            ]"
          >
            <label>
              <i class="far fa-user" />
            </label>
            <input
              v-model="account"
              type="text"
              @click="accountClicked = true"
              @keyup.enter="login()"
            />
          </div>

          <div
            class="input_box"
            :class="[
              { redUnderline: !password.length && passwordClicked == true },
              { greenUnderline: password.length },
            ]"
          >
            <label>
              <i class="fas fa-lock" />
            </label>
            <input
              v-model="password"
              type="password"
              @click="passwordClicked = true"
              @keyup.enter="login()"
            />
          </div>
        </div>
        <div class="button_group">
          <div class="button" @click.prevent="login()">
            <span class="button_text">
              {{ $t("_login") }}
            </span>
          </div>

          <div class="button" @click.prevent="demoFun">
            <span class="button_text">
              {{ $t("_testplay") }}
            </span>
          </div>

          <!-- <div class="button" @click.prevent="goToGame">
            <span class="button_text">
              {{ $t("_managebackend") }}
            </span>
          </div> -->
        </div>

        <div v-show="incorrect" ref="incorrect">
          <div class="error_text">
            <i class="fas fa-exclamation-circle" />
            <span>
              {{ $t("_empty") }}
            </span>
          </div>
        </div>
      </form>

      <!-- <div id="user-info">
        <i class="fas fa-tv" />
        <div>
          <p>FV: 41.2.1</p>
          <p id="browser">BR: {{ device.browser }}</p>
          <p>BV: 41.4.0</p>
          <p>IP: {{ device.client_ip }}</p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import md5 from "md5";
// import * as api from "@/api/webAPI";
// import * as service from "@/api/user";
import { Loading } from "element-ui";
// import { Base64 } from "js-base64";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      title: glob.title,
      languages: [
        {
          img: require("@/assets/images/hk.svg"),
          name: "zht",
        },
        {
          img: require("@/assets/images/cn.svg"),
          name: "zhs",
        },
        {
          img: require("@/assets/images/US.svg"),
          name: "eng",
        },
        // {
        //   img: require("@/assets/images/korea.svg"),
        //   name: "korea",
        // },
        // {
        //   img: require("@/assets/images/ru.svg"),
        //   name: "ru",
        // },
        // {
        //   img: require("@/assets/images/vietnam.svg"),
        //   name: "vietnam",
        // },
        // {
        //   img: require("@/assets/images/malaysia.svg"),
        //   name: "malaysia",
        // },
        // {
        //   img: require("@/assets/images/spain.svg"),
        //   name: "spain",
        // },
        // {
        //   img: require("@/assets/images/jp.svg"),
        //   name: "jp",
        // },
        // {
        //   img: require("@/assets/images/indo.svg"),
        //   name: "indo",
        // },
        // {
        //   img: require("@/assets/images/thai.svg"),
        //   name: "thai",
        // },
      ],
      showLangs: false,
      account: "",
      password: "",
      accountClicked: false,
      passwordClicked: false,
      device: {},
      message: [this.$t("_wrong"), this.$t("_empty")],
      incorrect: false,
      IP: null,
      currentLan: "zht",
      // bg: require("@/assets/images/BG.jpg"),
      // logo: require("@/assets/images/LOGO.svg"),
      // bg: require("@/assets/images/BG2.jpg"),
      // logo: require("@/assets/images/HYD.svg"),
      // bg:
      //   glob.mode === "leli"
      //     ? require("@/assets/images/LeliGameLoginBG.png")
      //     : require("@/assets/images/BG2.jpg"),
      // logo:
      //   glob.mode === "leli"
      //     ? require("@/assets/images/LOGO.svg")
      //     : require("@/assets/images/HYD.svg"),
      logo: require(`@/assets/images/${glob.logo}`),
      getWidthEvent: undefined,
      currentWidth: 0,
    };
  },
  computed: {
    getBroswer() {
      // 瀏覽器資訊
      var Sys = {};
      var ua = navigator.userAgent.toLowerCase();
      var s;
      s === ua.match(/edge\/([\d.]+)/)
        ? (Sys.edge = s[1])
        : s === ua.match(/rv:([\d.]+)\) like gecko/)
        ? (Sys.ie = s[1])
        : s === ua.match(/msie ([\d.]+)/)
        ? (Sys.ie = s[1])
        : s === ua.match(/firefox\/([\d.]+)/)
        ? (Sys.firefox = s[1])
        : s === ua.match(/chrome\/([\d.]+)/)
        ? (Sys.chrome = s[1])
        : s === ua.match(/opera.([\d.]+)/)
        ? (Sys.opera = s[1])
        : s === ua.match(/version\/([\d.]+).*safari/)
        ? (Sys.safari = s[1])
        : 0;

      if (Sys.edge) return "Edge-" + Sys.edge;
      if (Sys.ie) return "IE-" + Sys.ie;
      if (Sys.firefox) return "Firefox-" + Sys.firefox;
      if (Sys.chrome) return "Chrome-" + Sys.chrome;
      if (Sys.opera) return "Opera-" + Sys.opera;
      if (Sys.safari) return "Safari-" + Sys.safari;
      return { broswer: "", version: "0" };
    },
  },
  created() {
    var lan = localStorage.getItem("currentLan");
    var language =
      JSON.parse(localStorage.getItem("language")) || this.languages[0];
    var index = localStorage.getItem("currentLanIdx", index);
    if (!lan) {
      lan = "zht";
      index = 0;
      language = this.languages[0];
    }
    this.$i18n.locale = lan;
    console.log("lan: ", lan);
    this.selectLang(language, lan, index);
    this.showLangs = false;
  },
  mounted() {
    this.$refs.login_page.style.backgroundColor = glob.loginBgColor;
    this.resizeScreen();
    this.getWidthEvent = window.addEventListener("resize", this.resizeScreen);

    axios
      .get("/api/device")
      .then((res) => {
        this.device = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    selectLang(language, lan, index) {
      this.showLangs = !this.showLangs;
      if (lan === this.currentLan) {
        return;
      } else {
        localStorage.setItem("currentLan", lan);
        localStorage.setItem("currentLanIdx", index);
        localStorage.setItem("language", JSON.stringify(language));
        this.currentLan = lan;
        console.log("index: ", index);
        console.log("lan: ", lan);
        this.$i18n.locale = lan;
        this.languages.splice(index, 1);
        this.languages.unshift(language);
        setTimeout(() => {
          const hr = this.$refs.hr;
          if (this.showLangs) {
            hr[hr.length - 1].style.display = "none";
          }
        }, 0);
      }
    },
    login() {
      const loadingInstance = Loading.service({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // 檢查帳號或密碼是否為空
      const loginParams = {
        account: this.account,
        passwd: md5(`${this.password}`),
        game: true,
      };

      if (!this.account.length || !this.password.length) {
        this.$refs.form.classList.add("shake");
        this.$refs.incorrect.classList.add("incorrect");
        loadingInstance.close();
        this.incorrect = true;
        setTimeout(() => {
          this.$refs.form.classList.remove("shake");
        }, 1000);
      } else {
        this.incorrect = false;
        // service.login(loginParams);

        // const windowReference = window.open();

        axios
          .post("/api/login", loginParams)
          .then((res) => {
            console.log(res);
            if (res.data.result == "success") {
              axios
                .get("/api/bet360/oauth")
                .then((res) => {
                  console.log(res);
                  if (res.data.result == "success") {
                    loadingInstance.close();
                    this.makeUrl(true, res.data.url);
                  } else {
                    loadingInstance.close();
                    if (res.data.msg == "Invalid target id") {
                      this.$message({
                        message: this.$t("_wrong"),
                        type: "error",
                        center: true,
                      });
                    } else if (res.data.msg == "Invalid account or password") {
                      this.$message({
                        message: this.$t("_wrong"),
                        type: "error",
                        center: true,
                      });
                    } else {
                      this.$message({
                        message: res.data.msg,
                        type: "error",
                        center: true,
                      });
                    }
                  }
                })
                .catch((err) => {
                  this.$message({
                    message: this.$t("_networkError"),
                    type: "error",
                    center: true,
                  });
                  loadingInstance.close();

                  console.log(err);
                });
            } else {
              loadingInstance.close();
              if (res.data.msg == "Invalid target id") {
                this.$message({
                  message: this.$t("_wrong"),
                  type: "error",
                  center: true,
                });
              } else if (res.data.msg == "Invalid account or password") {
                this.$message({
                  message: this.$t("_wrong"),
                  type: "error",
                  center: true,
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                  center: true,
                });
              }
            }
          })
          .catch((err) => {
            if (err.response) {
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
              if (
                err.response.status === 403 &&
                err.response.data.msg === "Your ip is not in the whitelist"
              ) {
                this.$message({
                  message: this.$t("_ipNotInWhitelist"),
                  type: "error",
                  center: true,
                });
                // loadingInstance.close();
              } else {
                this.$message({
                  message: this.$t("_networkError"),
                  type: "error",
                  center: true,
                });
                loadingInstance.close();
              }
            }
            loadingInstance.close();
            console.log(err);
          });
      }
    },
    goToGame() {
      // window.location.href = "//win6689.com";
      // window.location.href = "//cc.hydcasino.com";
      // window.location.href = glob.managebackendurl;
      window.open(glob.managebackendurl);
    },
    resizeScreen() {
      var currentWidth = window.innerWidth;
      this.currentWidth = currentWidth;
      if (this.currentWidth <= 600) {
        this.$refs.login_page.style.backgroundImage =
          "url('" + require(`@/assets/images/${glob.loginBgImagePhone}`) + "')";
      } else {
        this.$refs.login_page.style.backgroundImage =
          "url('" + require(`@/assets/images/${glob.loginBgImage}`) + "')";
      }
    },
    demoFun() {
      const loadingInstance = Loading.service({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // const windowReference = window.open();

      axios
        .get("/api/bet360/testplay")
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            loadingInstance.close();
            this.makeUrl(false, res.data.url);
          } else {
            loadingInstance.close();
            if (res.data.msg == "Reach maximum account usage") {
              this.$message({
                message: this.$t("_testplaywrong"),
                type: "error",
                center: true,
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
                center: true,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    makeUrl(type, url) {
      // console.log("makeUrl", type, url);
      let jumpUrl = url;
      if (type) {
        jumpUrl += `&101token=${Cookies.get("token")}`;
      }
      jumpUrl += `&remove101token=${true}`;
      jumpUrl += `&101api=${glob.managebackendurl}`;
      jumpUrl += `&101domain=${window.location.href.split("#")[0]}`;

      jumpUrl += `&101logo=${
        window.location.href.split("#")[0]
      }${this.logo.slice(1)}`;
      jumpUrl += `&101title=${glob.gameTitle}`;

      console.log(jumpUrl);
      window.location.replace(jumpUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/globalstyle.scss";

#login-page {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  // justify-content: center;
  #login-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    // min-height: 650px;
    margin-left: 12%;
    background-image: url("~@/assets/images/Frame.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 25px;
    .lang {
      position: absolute;
      top: 165px;
      right: 25px;
      width: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: rgba(0, 0, 0, 0.35);
      cursor: pointer;
      padding: 10px 0;
      div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        hr {
          width: 80%;
          background: #f9c901;
          height: 1px;
          border: none;
          &:nth-child(3) {
            background: red;
          }
        }
        img {
          width: 30px;
          height: 30px;
          margin: 6px;
        }
      }
      .language {
        color: #f9c901;
        margin-bottom: 10px;
        // background: #88888888;
        // position: absolute;
        // top: 0px;
        // right: 20px;
      }
    }
    .noBg {
      background: none !important;
    }
    #logo-img {
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      color: #fff;
      margin-bottom: 5px;
    }
    h1 {
      font-size: 22px;
      text-align: center;
      font-weight: bold;
      margin-bottom: 1.41667rem;
      color: #b99e71;
    }
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
      .input_area {
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .input_box {
          width: 85%;
          height: 40px;
          display: flex;
          align-items: center;
          // background: rgba(0, 0, 0, 0.35);
          // margin-bottom: 20px;
          // border-top-right-radius: 5px;
          // border-top-left-radius: 5px;
          // border-bottom: 3px solid #f9c901;
          cursor: pointer;
          border: #ffffff 1.5px solid;
          border-radius: 25px;
          label {
            i {
              width: 22px;
              height: 22px;
              font-size: 22px;
              color: #ffffff;
              padding-left: 25px;
            }
          }
          input {
            width: 100%;
            height: 100%;
            margin-left: 28px;
            border: none;
            outline: none;
            background: transparent;
            color: #fff;
            cursor: pointer;
            font-size: 18px;
          }
        }
        .input_margin {
          margin-bottom: 10px;
        }
        .redUnderline {
          border: 3px solid #ff473d;
        }
        // .greenUnderline {
        // border: 3px solid #56ad10;
        // }
      }
      .button_group {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 85%;
        .button {
          margin-top: 10px;
          // width: 85%;
          width: 100%;
          height: 42px;
          // background: #f9c901;
          border: none;
          outline: none;
          // border-radius: 25px;
          // border: #ffffff 2px solid;
          background-image: url("~@/assets/images/Login.svg");
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .button_text {
            color: #ffffff;
            font-weight: bold;
            font-size: 16px;
          }
        }
      }
      .incorrect {
        width: 95%;
        color: #ff473d;
        font-size: 14px;
        text-shadow: 1px 1px 13px #000;
        display: flex;
        justify-content: center;
        .error_text {
          width: 100%;
          text-align: center;
          margin-bottom: 5px;
          margin-top: 10px;
          .fa-exclamation-circle {
            font-size: 20px;
            position: relative;
            top: 2px;
            margin-right: 5px;
          }
        }
      }
    }
    .shake {
      animation: shake 0.5s ease both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }

    @keyframes shake {
      10%,
      90% {
        transform: translate3d(-8px, 0, 0);
      }

      20%,
      80% {
        transform: translate3d(5px, 0, 0);
      }

      30%,
      50%,
      70% {
        transform: translate3d(-10px, 0, 0);
      }

      40%,
      60% {
        transform: translate3d(10px, 0, 0);
      }
    }
    #user-info {
      margin-top: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .fas {
        // color: #b99e71;
        color: #f9c901;
        font-size: 30px;
      }
      div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 60%;
        height: 36px;
        margin-left: 5px;
        p {
          width: 45%;
          display: flex;
          height: 30px;
          // color: #999;
          color: #f9c901;
          font-size: 12px;
          margin: 0;
          justify-content: flex-start;
        }
        #broswer {
          width: 50%;
        }
      }
    }
  }
}

@media (max-width: 950px) {
  #login-page {
    justify-content: center;
    #login-box {
      margin-left: 0;
    }
  }
}

@media (max-height: 600px) {
  #login-page {
    justify-content: unset;
    #login-box {
      margin-left: 5%;
      transform: scale(0.7);
    }
  }
}

@media (max-width: 280px) {
  #login-page {
    justify-content: center;
    #login-box {
      margin-left: 0;
      transform: scale(0.7);
    }
  }
}

@media (max-height: 300px) {
  #login-page {
    // justify-content: center;
    justify-content: unset;
    #login-box {
      // margin-left: 10%;
      transform: scale(0.5);
    }
  }
}
</style>
